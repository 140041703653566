import Alpine from "alpinejs";
import Clipboard from "@ryangjchandler/alpine-clipboard";
import "@fortawesome/fontawesome-free/js/all";
import "./sprinkles/programs.js";
import "./sprinkles/appointmentDeepLink.js";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "htmx.org";
import TomSelect from "tom-select";
import "tom-select/dist/scss/tom-select.scss";
function initializeTomSelect() {
  document.querySelectorAll(".tom-select").forEach((selectElement) => {
    if (selectElement.tomselect) {
      selectElement.tomselect.destroy();
    }

    new TomSelect(selectElement);
  });
}
document.addEventListener("DOMContentLoaded", initializeTomSelect);

document.addEventListener("htmx:afterSettle", initializeTomSelect);

// boot tippy
function bootTippy() {
  tippy("[data-tippy-content]");
  tippy("[data-tippy-content-id]", {
    content(reference) {
      const id = reference.getAttribute("data-tippy-content-id");
      const template = document.getElementById(id);
      return template.innerHTML;
    },
    allowHTML: true,
  });
}
window.bootTippy = bootTippy;
Alpine.plugin(
  Clipboard.configure({
    onCopy: () => {
      console.log("Copied!");
    },
  })
);
window.Alpine = Alpine;
window.Alpine.start();
window.htmx = require("htmx.org");
function addDataConfirmHandlers() {
  const dataConfirm = document.querySelectorAll("[data-confirm]");
  dataConfirm.forEach((element) => {
    element.addEventListener("click", (e) => {
      const message = element.dataset.confirm;
      if (window.confirm(message)) {
        return true;
      } else {
        e.preventDefault();
        return false;
      }
    });
  });
}

document.addEventListener("DOMContentLoaded", addDataConfirmHandlers, false);
document.addEventListener("DOMContentLoaded", bootTippy, false);

function bootTomSelectById(ids) {
  return function () {
    ids.forEach((id) => {
      const el = document.getElementById(id);
      if (el) {
        if (el.tomselect) {
          el.tomselect.destroy();
        }
        new TomSelect(el);
      }
    });
  };
}

// Used in homebase/templates/treatability_management/_treatability_insurance_section.html
document.addEventListener(
  "DOMContentLoaded",
  bootTomSelectById(["id_payers", "id_insurance_types", "id_programs"])
);
